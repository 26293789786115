// require('jquery');
// require('popper.js');
// require('bootstrap');
// global.jQuery = global.$ = require('jquery');
import Tooltip from '../../../../assessments/static/assessments/scripts/_tooltip';

// if an outdated browser is present, show the unsupported browser message
const { detect } = require('detect-browser');
window.addEventListener('load', function() {
    const browser = detect();
    if (browser && (browser.name == 'ie') && (window.location.href.indexOf('unsupported') == -1)) {
        document.getElementById('unsupported-browser').classList.remove('d-none');
        window.location.href = '/unsupported/';
    }
});

document.addEventListener('DOMContentLoaded', () => {
    // add scrollspy to any `#navigation-scrollspy` elements
    if(document.querySelector('#navigation-scrollspy')) {
        $('body').scrollspy({ target: '#navigation-scrollspy' })
    }

    // initialize criteria tooltips
    document.querySelectorAll('.btn-tooltip').forEach((el) => { new Tooltip(el); });
});